document.addEventListener('DOMContentLoaded', function () {
    $('form.zipdownload :button').on('click', function (event) {
        var checkedLinks = $(this).closest('form.zipdownload').find('input[type=checkbox]').filter(':checked').siblings('a');

        if (!checkedLinks.length) {
            // No Downloads selected -> do nothing
            event.stopPropagation();
            event.preventDefault();
            return false;
        } else {
            checkedLinks.each(function () {
                eval($(this).attr('onClick'));
            });
        }
    });
});
