/*jslint browser: true, nomen: false, devel: true, white: true */
/*global $, jQuery, Modernizr */
import jQuery from 'jquery';
import $ from 'jquery';
import * as lity from 'lity';
import 'magnific-popup';
import videojs from 'video.js';
import './../../../../../web/typo3conf/ext/femanager/Resources/Public/JavaScript/Femanager.min.js';
import './../../../../../web/typo3conf/ext/femanager/Resources/Public/JavaScript/Validation.min.js';
import './../../../../../local/evva_securedownload/Resources/Public/js/trackdownload';

import './../css/evvadatasheet/tm_style.css';

(function () {
    'use strict';
    videojs.options.autoSetup = false;
    (function ($) {

        $.fn.shuffle = function () {

            var allElems = this.get(),
                getRandom = function (max) {
                    return Math.floor(Math.random() * max);
                },
                shuffled = $.map(allElems, function () {
                    var random = getRandom(allElems.length),
                        randEl = $(allElems[random]).clone(true)[0];
                    allElems.splice(random, 1);
                    return randEl;
                });

            this.each(function (i) {
                $(this).replaceWith($(shuffled[i]));
            });

            return $(shuffled);

        };

    })(jQuery);

    $.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results === null) {
            return null;
        } else {
            return results[1] || 0;
        }
    };

    $(function () {
        if ($('.m-news-list-randomize').length > 0 && $('.m-news-list').length > 0) {
            var finalNews,
                neededNews = 10 - 1;
            $('.m-news-list-randomize').each(function () {
                $('.m-news-item', this).shuffle();
            });
            $('.m-news-item:gt(' + neededNews + ')').remove();
            finalNews = $('.m-news-item');
            $('.m-news-list-randomize').parent().remove();
            $('.m-news-list:gt(0)').parent('.csc-default').remove();
            $('.m-news-list .row').remove();
            $('.m-news-list').html(finalNews);

            $('.m-news-item:odd', this).wrap('<div class=\'small-12 large-6 end columns\'></div>');
            $('.m-news-item:even', this).wrap('<div class=\'small-12 large-6 columns\'></div>');
            for (var i = 0; i < $(".columns", ".m-news-list").length; i += 2) {
                $('.columns', '.m-news-list').slice(i, i + 2).wrapAll('<div class=\'row\'></div>');
            }
        }
    });

    $(function () {
        if ($('.m-news-list-randomize-single').length > 0) {
            $('.m-news-list-randomize-single').each(function () {
                $('.m-news-item', this).shuffle();
            });
        }
    });

    $(document).ready(function () {
        $('.lightbox').magnificPopup({ type: 'image' });

        if ($('.news-backlink-wrap a').length > 0) {
            if (document.referrer.indexOf(window.location.hostname) !== -1) {
                $('.news-backlink-wrap a').attr('href', 'javascript:history.go(-1);');
            }
        }
        if ($.urlParam('return_url') && $('.loginbox').length) {
            $('body').prepend('<div class="mask"></div>');
            $('.loginbox').addClass('pulse');
            $('.mask').click(function () {
                $(this).fadeOut().remove();
                $('.loginbox').removeClass('pulse');
            });
        }

        if ($('.pppopup').length > 0) {
            // get popup options
            var ppp = $('.pppopup'),
                pppSleep = ppp.data('pppopup-sleep') * 1000, // Seconds > milliseconds
                pppWidthGross = ppp.data('pppopup-width') + 20, // gross width
                pppId = ppp.data('pppopup-id');

            if ($('#pppopup-default', ppp).length > 0 && $(window).width() > pppWidthGross) {
                setTimeout(function () {
                    $('#pppopup-default').modal('show');
                }, pppSleep);
                $('#pppopup-default').on('show.bs.modal', function (e) {
                    $.ajax({
                        type: 'POST',
                        url: EvvaPpPopup.popupCountAjaxUri,
                        data: { 'tx_evvapppopup_popup[popupId]': pppId }
                    });

                });
            }

            if ($('#pppopup-iframe', ppp).length > 0 && $(window).width() > pppWidthGross) {
                setTimeout(function () {
                    $('#pppopup-iframe').modal('show');
                }, pppSleep);
                $('#pppopup-iframe').on('show.bs.modal', function (e) {
                    $.ajax({
                        type: 'POST',
                        url: EvvaPpPopup.popupCountAjaxUri,
                        data: { 'tx_evvapppopup_popup[popupId]': pppId }
                    });

                });
            }

            $('.pppopup--cta_close').click(function () {
                $.magnificPopup.close();
            });
        }

        // *******************************
        // open video in a lightbox:
        $(document).on('click', function () {
            var lityVideo = document.getElementById('lityVideo');

            if (lityVideo && !lityVideo.paused) {
                lityVideo.pause();
            }

        });
        // *******************************

        // *******************************
        // control the size of play-icons for the videos:
        var contentElements = $('.content-element');

        resizeVideoPlayIcon(contentElements);

        $(window).resize(function () {
            var contentElements = $('.content-element');
            resizeVideoPlayIcon(contentElements);
        });
        // *******************************

        // *******************************

        if (jQuery('.videoPreviewStartPage').length > 0) {
            videojs.options.autoSetup = false;
            var videos=document.querySelectorAll('.videoPreviewStartPage');
            videos.forEach(function(item){
              videojs(item);
            });

            // process the video-preview: set loop-duration
            jQuery.each(videojs.getPlayers(), function (key, videoItem) {
                var loopDuration = videoItem.tagAttributes['data-duration'];
                var interval = 1000 * loopDuration;
                videoItem.one('loadedmetadata', function () {
                    var lengthOfVideo = videoItem.duration();
                    if (loopDuration != 0 && loopDuration < parseInt(lengthOfVideo)) {
                        setInterval(replayVideo, interval, videoItem);
                    }
                    videoItem.play();
                });
            });
        }
        // *******************************

    });

    $('.collapse').on('show.bs.collapse', function () {
        $(this).prev().addClass('active');
    }).on('hide.bs.collapse', function () {
        $(this).prev().removeClass('active');
    });

    function replayVideo (video) {
        video.currentTime(0);
    }

    $('.playVideo').click(function (e) {
        e.preventDefault();
        var url = this.getAttribute('data-url');
        var ext = this.getAttribute('data-extension');
        var title = this.getAttribute('data-title');
        lity('<video id="lityVideo" preload="auto" autoplay="autoplay" controls="true"' + (title ? ' data-title="' + title + '"' : '') + '><source src="' + url + '" type="video/' + ext + '"></video>');
    });

    function resizeVideoPlayIcon (gridelementsTextmedia) {
        gridelementsTextmedia.each(function () {
           // var videothumb = $(this).find($('.videoThumbExternal'));
            var container = $(this).find($('.news-media'));
            var width1 = container.width();
            var height1 = container.height();
            var playIcon = $(this).find($('.videoPlayIcon'));
            playIcon.css('width', width1);
            playIcon.css('height', height1);
           // var videoPreviewStartPage = $(this).find($('.vjs-tech'));
           // var width2 = videoPreviewStartPage.width();
           // var height2 = videoPreviewStartPage.height();
            var videoPlayNotVisibleDiv = $(this).find($('.videoPlayNotVisibleDiv'));
            videoPlayNotVisibleDiv.css('width', width1);
            videoPlayNotVisibleDiv.css('height', height1);
            var mediaElementVideo = $(this).find($('.mediaelement-video'));
            if (mediaElementVideo){
                mediaElementVideo.css('height','200px');
            }
        });
    }

    window.replayVideo = replayVideo;
    window.resizeVideoPlayIcon = resizeVideoPlayIcon;

}());

function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

(function () {
    $(document).ready(function () {
        // After login
        if (window.EvvaPartner !== undefined) {
            // Remove cHash parameter
            $('a#link-logout').attr('href', $('a#link-logout').attr('href').replace(/[&?]cHash=[0-9a-z]{32}/, ''));
            // check after 20 minutes from now …
            var deadline = new Date(new Date().getTime() + 20 * 60000);
            var checkTimeout = setInterval(function () {
                var difference = deadline - new Date().getTime();
                if (difference <= 0) {
                    // … and logout the user with a redirect url to return to the current page
                    window.location.href = $('a#link-logout').attr('href') + '&auto_logout=1&return_url=' + encodeURIComponent(window.location.pathname);
                    clearTimeout(checkTimeout);
                }
            }, 1000);
            $(window).on('mousemove keypress click', function () {
                deadline = new Date(new Date().getTime() + 20 * 60000);
            });
        }
    });
}());
